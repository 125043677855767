import { FETCH_COMPETITIONS_START, FETCH_COMPETITIONS_SUCCESS } from "./actionTypes";

export const fetchCompetitionsStart = (params = {})=>{
  return {
    type:FETCH_COMPETITIONS_START,
    payload:params
  };
};
export const fetchCompetitionsSuccess = (data)=>{
  return {
    type:FETCH_COMPETITIONS_SUCCESS,
    payload:data
  };
};