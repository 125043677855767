
import {
  GET_COMPETITIONS_START,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAIL,
  CREATE_COMPETITION_START,
  CREATE_COMPETITION_SUCCESS,
  CREATE_COMPETITION_FAIL,
  CLEAR_COMPETITIONS_STATE
} from "./actionTypes";

export const clearCompetitionsState = () => {
  return {
    type: CLEAR_COMPETITIONS_STATE,
  };
};

export const getCompetitionsStart = (params) => {
  return {
    type: GET_COMPETITIONS_START,
    payload: params
  };
};

export const getCompetitionsSuccess = (data) => {
  return {
    type: GET_COMPETITIONS_SUCCESS,
    payload: data
  };
};

export const getCompetitionsFail = (error) => {
  return {
    type: GET_COMPETITIONS_FAIL,
    payload: error
  };
};

export const createCompetitionStart = (payload) => {
  return {
    type: CREATE_COMPETITION_START,
    payload,
  };
};

export const createCompetitionSuccess = (params = {}) => {
  return {
    type: CREATE_COMPETITION_SUCCESS,
    payload: params
  };
};

export const createCompetitionFail = (params = {}) => {
  return {
    type: CREATE_COMPETITION_FAIL,
    payload: params
  };
};
