
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { createCompetitionStart } from "store/forex/ib/competition/actions";

const CreateCompetitionModal = ({ isOpen, toggle, t }) => {
  const dispatch = useDispatch();
  const { submitting } = useSelector((state) => state?.forex?.ib?.competitionsReducer) || {};

  const [formData, setFormData] = useState({
    address: "",
    info: "",
    competitionDate: "",
    prizes: [""],
  });

  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error for this field if it exists
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handlePrizeChange = (index, value) => {
    const updatedPrizes = [...formData.prizes];
    updatedPrizes[index] = value;
    
    setFormData({
      ...formData,
      prizes: updatedPrizes,
    });
  };

  const addPrizeField = () => {
    if (formData.prizes.length < 10) {
      setFormData({
        ...formData,
        prizes: [...formData.prizes, ""],
      });
    }
  };

  const removePrizeField = (index) => {
    if (formData.prizes.length > 1) {
      const updatedPrizes = formData.prizes.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        prizes: updatedPrizes,
      });
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length + images.length > 4) {
      setErrors({
        ...errors,
        images: "Maximum 4 images allowed",
      });
      return;
    }
    
    // Validate file types
    const invalidFiles = files.filter(file => 
      !["image/jpeg", "image/png"].includes(file.type)
    );
    
    if (invalidFiles.length > 0) {
      setErrors({
        ...errors,
        images: "Only JPG and PNG files are allowed",
      });
      return;
    }
    
    // Validate file sizes (max 10MB each)
    const oversizedFiles = files.filter(file => file.size > 10000000);
    
    if (oversizedFiles.length > 0) {
      setErrors({
        ...errors,
        images: "Images must be less than 10MB each",
      });
      return;
    }
    
    setImages([...images, ...files]);
    
    // Create preview URLs
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setImagePreview([...imagePreview, ...newPreviews]);
    
    // Clear error if it exists
    if (errors.images) {
      setErrors({
        ...errors,
        images: "",
      });
    }
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    
    // Revoke the object URL to avoid memory leaks
    URL.revokeObjectURL(imagePreview[index]);
    const updatedPreviews = imagePreview.filter((_, i) => i !== index);
    
    setImages(updatedImages);
    setImagePreview(updatedPreviews);
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
    }
    
    if (!formData.info.trim()) {
      newErrors.info = "Information is required";
    }
    
    if (!formData.competitionDate) {
      newErrors.competitionDate = "Competition date is required";
    }
    
    // Check if at least one prize has a value
    const hasPrize = formData.prizes.some(prize => prize.trim() !== "");
    if (!hasPrize) {
      newErrors.prizes = "At least one prize is required";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    // Filter out empty prize strings
    const nonEmptyPrizes = formData.prizes.filter(prize => prize.trim() !== "");
    
    const competitionData = {
      ...formData,
      prizes: nonEmptyPrizes,
      images: images,
    };
    
    dispatch(createCompetitionStart(competitionData));
  };

  const closeModal = () => {
    // Reset form
    setFormData({
      address: "",
      info: "",
      competitionDate: "",
      prizes: [""],
    });
    setImages([]);
    setImagePreview([]);
    setErrors({});
    
    // Revoke all object URLs
    imagePreview.forEach(url => URL.revokeObjectURL(url));
    
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal} centered size="lg">
      <ModalHeader toggle={closeModal}>{t("Create New Competition")}</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="address">{t("Address")} *</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  invalid={!!errors.address}
                />
                {errors.address && <FormFeedback>{errors.address}</FormFeedback>}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="competitionDate">{t("Competition Date")} *</Label>
                <Input
                  type="date"
                  name="competitionDate"
                  id="competitionDate"
                  value={formData.competitionDate}
                  onChange={handleInputChange}
                  invalid={!!errors.competitionDate}
                />
                {errors.competitionDate && <FormFeedback>{errors.competitionDate}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="info">{t("Information")} *</Label>
            <Input
              type="textarea"
              name="info"
              id="info"
              rows="4"
              value={formData.info}
              onChange={handleInputChange}
              invalid={!!errors.info}
            />
            {errors.info && <FormFeedback>{errors.info}</FormFeedback>}
          </FormGroup>

          <FormGroup>
            <Label>{t("Prizes")}</Label>
            {formData.prizes.map((prize, index) => (
              <div key={index} className="d-flex mb-2">
                <Input
                  type="text"
                  value={prize}
                  onChange={(e) => handlePrizeChange(index, e.target.value)}
                  placeholder={`Prize ${index + 1}`}
                  className="me-2"
                  invalid={index === 0 && !!errors.prizes}
                />
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => removePrizeField(index)}
                  disabled={formData.prizes.length === 1}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </div>
            ))}
            {errors.prizes && <div className="text-danger small mb-2">{errors.prizes}</div>}
            
            <Button
              type="button"
              color="info"
              size="sm"
              onClick={addPrizeField}
              disabled={formData.prizes.length >= 10}
              className="mt-1"
            >
              <i className="bx bx-plus me-1"></i>
              {t("Add Prize")}
            </Button>
          </FormGroup>

          <FormGroup>
            <Label for="images">{t("Images")} (Max 4)</Label>
            <Input
              type="file"
              name="images"
              id="images"
              accept="image/jpeg, image/png"
              onChange={handleImageChange}
              multiple
              invalid={!!errors.images}
            />
            {errors.images && <FormFeedback>{errors.images}</FormFeedback>}
            
            {imagePreview.length > 0 && (
              <div className="mt-3">
                <div className="d-flex flex-wrap">
                  {imagePreview.map((src, index) => (
                    <div key={index} className="position-relative me-3 mt-2">
                      <img
                        src={src}
                        alt={`Preview ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover" 
                        }}
                        className="rounded"
                      />
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => removeImage(index)}
                        className="position-absolute top-0 end-0 rounded-circle p-0"
                        style={{
                          width: "24px",
                          height: "24px" 
                        }}
                      >
                        <i className="bx bx-x"></i>
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal} disabled={submitting}>
            {t("Cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={submitting}>
            {submitting ? (
              <>
                <Spinner size="sm" className="me-2" />
                {t("Creating...")}
              </>
            ) : (
              t("Create Competition")
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(CreateCompetitionModal);