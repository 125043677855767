
import {
  GET_COMPETITIONS_START,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAIL,
  CREATE_COMPETITION_START,
  CREATE_COMPETITION_SUCCESS,
  CREATE_COMPETITION_FAIL,
  CLEAR_COMPETITIONS_STATE
} from "./actionTypes";

const initialState = {
  loading: false,
  submitting: false,
  error: "",
  competitions: [],
  competitionsPagination: {
    hasNextPage: false,
    nextPage: null,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
    page: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    prevPage: null,
  }
};

const competitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_COMPETITIONS_STATE:
      return {
        ...state,
        competitions: [],
        error: ""
      };
    case GET_COMPETITIONS_START:
      return {
        ...state,
        error: "",
        loading: true
      };
    case GET_COMPETITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        competitions: [...action.payload.docs],
        competitionsPagination: {
          hasNextPage: action.payload.hasNextPage,
          nextPage: action.payload.nextPage,
          limit: action.payload.limit,
          totalDocs: action.payload.totalDocs,
          totalPages: action.payload.totalPages,
          page: action.payload.page,
          pagingCounter: action.payload.pagingCounter,
          hasPrevPage: action.payload.hasPrevPage,
          prevPage: action.payload.prevPage,
        }
      };
    case GET_COMPETITIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_COMPETITION_START:
      return {
        ...state,
        error: "",
        submitting: true,
      };
    case CREATE_COMPETITION_SUCCESS:
      // Add the new competition to the existing list if we have it
      const competitions = state.competitions ? [action.payload.result, ...state.competitions] : [action.payload.result];
      return {
        ...state,
        competitions,
        error: "",
        submitting: false,
      };
    case CREATE_COMPETITION_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default competitionsReducer;