import React from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Table,
  Button
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { 
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import CustomPagination from "components/Common/CustomPagination";
import { Link } from "react-router-dom";

const RankingModal = ({ isOpen, toggle, t }) => {
  const ibSummaryData = useSelector((state) => state.walletReducer.report.data);
  // const ibDocs = [];
  const ibLoading = false;
  
  const ibColumns = [
    {
      dataField: "rank",
      text: "Rank",
      formatter: (val, rowIndex) => rowIndex + 1, // Assigning rank starting from 1
    },
    {
      dataField: "client",
      text: "Client",
      // formatter: (val) =>
      //   val?.customer ? (
      //     <Link to={`clients/${val?.customer?._id}/profile`}>
      //       {val?.customer
      //         ? `${val?.customer?.firstName} ${val?.customer?.lastName}`
      //         : "-"}
      //     </Link>
      //   ) : (
      //     "-"
      //   ),
      formatter: (val) =>
        val?.customer ? `${val?.customer?.firstName} ${val?.customer?.lastName}` : (
          "-"
        ),
    },
    {
      dataField: "lotsOpened",
      text: "Total Lots",
      formatter: (val, rowIndex) => val?.lotsOpened / 10000,
    },
    // {
    //   dataField: "lotsOpened",
    //   text: "Total Lots",
    // },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>{t("Competition Ranking")}</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-hover"
                >
                  <Thead className="text-center table-light">
                    <Tr>
                      {ibColumns.map((column, index) =>
                        <Th data-priority={index} key={index}>
                          <span className="color-primary">{column.text}</span>
                        </Th>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody className="text-center">
                    {ibLoading && <TableLoader colSpan={ibColumns.length} />}
                    {!ibLoading && ibSummaryData?.map((row, rowIndex) =>
                      <Tr key={rowIndex}>
                        {ibColumns.map((column, index) =>
                          <Td key={`${rowIndex}-${index}`}>
                            {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                          </Td>
                        )}
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {/* <CustomPagination
                  {...ibSummaryData}
                  docs={ibSummaryData}
                  sizePerPage={10}
                  onChange={() => {}}
                /> */}
              </div>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(RankingModal);