import { combineReducers } from "redux";
import ibClients from "./clients/reducer";
import transactions from "./transactions/reducer";
import agreementReducer from "./agreements/reducer";
import competitionsReducer from "./competition/reducer";
const ibReducer = combineReducers({
  clients:ibClients,
  competitionsReducer,
  transactions,
  agreements: agreementReducer
});

export default ibReducer;