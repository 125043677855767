import { 
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import { FETCH_COMPETITIONS_START } from "./actionTypes";

const { getCompetitions } = require("apis/IbCompition");
const { fetchCompetitionsSuccess } = require("./actions");

function * fetchCompetitions(params){
  try {
    const data = yield call(getCompetitions, params);
    yield put(fetchCompetitionsSuccess(data));
  } catch (error){
    yield put(error);
  }
    
}

function * competitionSaga(){
  yield takeEvery(FETCH_COMPETITIONS_START, fetchCompetitions);
}
export default competitionSaga;