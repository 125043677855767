// import CardWrapper from "components/Common/CardWrapper";
// import TableLoader from "components/Common/TableLoader";
// import PageHeader from "components/Forex/Common/PageHeader";
// import { useEffect, useState } from "react";
// import { withTranslation } from "react-i18next";
// import { MetaTags } from "react-meta-tags";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { 
//   Tbody,
//   Td,
//   Th,
//   Thead,
//   Tr
// } from "react-super-responsive-table";
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Row,
//   Table
// } from "reactstrap";
// import { fetchCompetitionsStart, fetchIbSummaryReport } from "store/actions";
// import CustomPagination from "components/Common/CustomPagination";
// import { contains } from "jquery";
// import CreateCompetitionModal from "../IBCompetitions/CreateCompetitionModal";
// const IbCompetition = (props)=> {
//   const competitions = useSelector((state) => state?.competitionReducer.competitions);
//   const ibSummaryData = useSelector((state)=> state.walletReducer.report.data);
//   const [sizePerPageIb, setSizePerPageIb] = useState(10);
//   const [currentPageIb, setcurrentPageIb] = useState(1);
//   const [showIbSummary, setShowIbSummary] = useState(false);
//   const ibDocs = [];
//   const ibLoading = false;

//   const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
//   const toggleCreateModal = () => {
//     setIsCreateModalOpen(!isCreateModalOpen);
//     setShowIbSummary(!showIbSummary);
//   };

//   const dispatch = useDispatch();

//   useEffect(()=> {
//     loadCompetitions();
//     loadIbSummary(currentPageIb, setSizePerPageIb);
//   }, []);
//   const loadCompetitions = (page, limit) => {
//     dispatch(fetchCompetitionsStart({
//       page,
//       limit,
//     }));
//   };
//   const currentMonth = new Date().toLocaleString("default", { month: "long" });
//   const loadIbSummary = (page, limit) => {
//     dispatch(fetchIbSummaryReport({
//       month:currentMonth,
//       page,
//       limit,
//     }));
//   };
//   const numPageRowsIb = (numOfRows) => {
//     setSizePerPageIb(numOfRows);
//   };
//   const ibColumns = [
//     {
//       dataField: "rank",
//       text: "Rank",
//       formatter: (val, rowIndex) => rowIndex + 1, // Assigning rank starting from 1
//     },
//     {
//       dataField: "client",
//       text: "Client",
//       formatter: (val) =>
//         val?.customer ? (
//           <Link to={`clients/${val?.customer?._id}/profile`}>
//             {val?.customer
//               ? `${val?.customer?.firstName} ${val?.customer?.lastName}`
//               : "-"}
//           </Link>
//         ) : (
//           "-"
//         ),
//     },
//     {
//       dataField: "totalLots",
//       text: "Total Lots",
//     },
//     // {
//     //   dataField: "agent",
//     //   text: "Agent",
//     //   formatter: (val) =>
//     //     val?.agent ? `${val.agent?.firstName} ${val.agent?.lastName}` : "-",
//     // },

//   ];
//   return (<>
  
//     <div className="page-content" >
//       <MetaTags>
//         <title>{props.t("IbCompetition")}</title>
//       </MetaTags>
//       <Container>
//         <h1 className="mt-5 mb-3"> Ib Competition </h1>
//         <CardWrapper className="mb-5 p-4 glass-card shadow">
          
//           <div>
//             <Row>
//               {
//                 competitions?.map((i)=> <Col key={i._id} lg={12}>
//                   <Card className=" p-4 glass-card shadow">  
//                     <CardBody>
//                       {/* <h3 className="text-center color-primary h6">
//                           Title: {i.address}
//                       </h3> */}
//                       <h3 className="text-center color-primary h6">
//                           Address: {i.address}
//                       </h3>
//                       <h3 className="text-center color-primary h6">
//                         Competition Information: {i.info}
//                       </h3>
//                       <h3 className="text-center color-primary h6">
//                         Competition Date: {i.competitionYear} - {i.competitionDate}
//                       </h3>
//                       <div className="text-center my-3">
//                         {/* <Button className="" onClick={() => setShowIbSummary(!showIbSummary)}> */}
//                         <Button className="" onClick={() => toggleCreateModal()}>
//                           Ranking
//                         </Button>
//                       </div>

//                       <div className="text-center mt-3 d-flex gap-2">
//                         {
//                           i.images.map((image)=> <div key={image} >
//                             <img style={{objectFit: "contain"}} src={image} width={250}/>
//                           </div> )
//                         }
//                       </div>
//                     </CardBody>
//                   </Card>   
//                 </Col>)
//               }
//             </Row>
//           </div> 
//           {showIbSummary && (
//             <Row>
//               <Col className="col-12">
//                 <Card>
              
//                   <CardBody>
//                     <div className="table-rep-plugin">
//                       <div
//                         className="table-responsive mb-0"
//                         data-pattern="priority-columns"
//                       >
//                         <Table
//                           id="tech-companies-1"
//                           className="table  table-hover "
//                         >
//                           <Thead className="text-center table-light" >
//                             <Tr>
//                               {ibColumns.map((column, index) =>
//                                 <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
//                               )}
//                             </Tr>
//                           </Thead>
//                           <Tbody className="text-center">
//                             {ibLoading && <TableLoader colSpan={6} />}
//                             {!ibLoading && ibDocs?.map((row, rowIndex) =>
//                               <Tr key={rowIndex}>
//                                 {ibColumns.map((column, index) =>
//                                   <Td key={`${rowIndex}-${index}`}>
//                                     {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
//                                   </Td>
//                                 )}
//                               </Tr>
//                             )}
//                           </Tbody>
//                         </Table>
//                         <CustomPagination
//                           {...ibSummaryData}
//                           docs={ibSummaryData}
//                           setSizePerPage={setSizePerPageIb}
//                           sizePerPage={sizePerPageIb}
//                           onChange={loadIbSummary}
//                         />
//                       </div>
//                     </div>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           )}
//         </CardWrapper>
//       </Container>

//       <CreateCompetitionModal
//         isOpen={isCreateModalOpen}
//         toggle={toggleCreateModal}
//       />
//     </div>
//   </>

//   );};

// export default withTranslation()(IbCompetition);

import CardWrapper from "components/Common/CardWrapper";
import PageHeader from "components/Forex/Common/PageHeader";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { fetchCompetitionsStart, fetchIbSummaryReport } from "store/actions";
import RankingModal from "./RankingModal";

const IbCompetition = (props) => {
  const competitions = useSelector((state) => state?.competitionReducer.competitions);
  const [sizePerPageIb, setSizePerPageIb] = useState(10);
  const [currentPageIb, setcurrentPageIb] = useState(1);
  const [showIbSummary, setShowIbSummary] = useState(false);

  const [isRankingModalOpen, setIsRankingModalOpen] = useState(false);
  const toggleRankingModal = () => {
    setIsRankingModalOpen(!isRankingModalOpen);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    loadCompetitions();
    loadIbSummary(currentPageIb, sizePerPageIb);
  }, []);

  const loadCompetitions = (page, limit) => {
    dispatch(fetchCompetitionsStart({
      page,
      limit,
    }));
  };

  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  const loadIbSummary = (page, limit) => {
    dispatch(fetchIbSummaryReport({
      month: currentMonth,
      page,
      limit,
    }));
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("IbCompetition")}</title>
        </MetaTags>
        <Container>
          <h1 className="mt-5 mb-3"> Ib Competition </h1>
          <CardWrapper className="mb-5 p-4 glass-card shadow">
            <div>
              <Row>
                {
                  competitions?.map((i) => <Col key={i._id} lg={12}>
                    <Card className="p-4 glass-card shadow">
                      <CardBody>
                        <h3 className="text-center color-primary">
                          {i.address} {i.competitionYear} - {i.competitionDate}
                        </h3>
                        <h4 className="color-primary h6 mt-3 ">
                          {i.info}
                        </h4>
                        {/* <h3 className="color-primary h6">
                          Competition Date: 
                        </h3> */}

                        <div className="mt-3 d-flex flex-column  align-items-start justify-content-start gap-2">
                          {/* <ul className="mt-3 d-flex flex-column gap-2 align-items-center justify-content-center w-100"> */}
                          <h3>Prize :</h3>
                          {
                            i.prizes.map((prize, index) => <h6 key={prize} className="px-4">Rank {index + 1} : {prize}</h6>)
                          }
                          {/* </ul> */}
                        </div>

                        <div className="text-center mt-3 d-flex gap-2">
                          {
                            i.images.map((image) => <div key={image}>
                              <img style={{ objectFit: "contain" }} src={image} width={250} />
                            </div>)
                          }
                        </div>
                        <div className="text-center mt-5">
                          <button className="btn btn-primary" onClick={toggleRankingModal}>
                            Competition Ranking
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>)
                }
              </Row>
            </div>
          </CardWrapper>
        </Container>

        <RankingModal
          isOpen={isRankingModalOpen}
          toggle={toggleRankingModal}
        />
      </div>
    </>
  );
};

export default withTranslation()(IbCompetition);