
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container, Row, Col, Card, CardBody, Button, Spinner 
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import CompetitionsList from "./CompetitionsList";
import CreateCompetitionModal from "./CreateCompetitionModal";
import { getCompetitionsStart } from "store/forex/ib/competition/actions";

const IBCompetitions = ({ t }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state) || {};
  const { loading } = useSelector((state) => state?.forex?.ib?.competitionsReducer) || {};
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    console.log("state", state?.forex?.ib);
  }, [state]);

  useEffect(() => {
    dispatch(getCompetitionsStart({
      page: 1,
      limit: 10 
    }));
  }, [dispatch]);

  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t("IB Competitions")}</title>
      </MetaTags>
      <Container>
        <Row>
          <Col lg={12}>
            {/* <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="card-title">{t("IB Competitions")}</h4>
              <Button
                color="primary"
                className="btn-sm"
                onClick={toggleCreateModal}
              >
                <i className="bx bx-plus me-1"></i>
                {t("Create New Competition")}
              </Button>
            </div> */}

            <Card>
              <CardBody>
                {loading ? (
                  <div className="d-flex justify-content-center p-5">
                    <Spinner size="lg" color="primary" />
                  </div>
                ) : (
                  <CompetitionsList />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <CreateCompetitionModal
        isOpen={isCreateModalOpen}
        toggle={toggleCreateModal}
      /> */}
    </div>
  );
};

export default withTranslation()(IBCompetitions);