
import {
  call, put, takeEvery, takeLatest
} from "redux-saga/effects";
import {
  getCompetitionsAPI,
  createCompetitionAPI,
} from "../../../../apis/competitions";
import {
  getCompetitionsSuccess,
  getCompetitionsFail,
  createCompetitionSuccess,
  createCompetitionFail,
} from "./actions";

import {
  GET_COMPETITIONS_START,
  CREATE_COMPETITION_START,
} from "./actionTypes";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../general/notifications/actions";
import { toggleCurrentModal } from "../../../actions";

function* fetchCompetitions({ payload }) {
  try {
    const data = yield call(getCompetitionsAPI, payload);
    if (data?.docs) {
      yield put(getCompetitionsSuccess(data));
    } else {
      yield put(getCompetitionsFail(data.message || "Failed to fetch competitions"));
    }
  } catch (error) {
    yield put(getCompetitionsFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

function* createCompetition({ payload }) {
  try {
    const result = yield call(createCompetitionAPI, payload);
    if (result.status) {
      yield put(createCompetitionSuccess(result));
      yield put(showSuccessNotification("Competition created successfully!"));
      yield put(toggleCurrentModal(""));
    } else {
      yield put(createCompetitionFail(result.message));
      yield put(showErrorNotification(result.message));  
    }
  }
  catch (error) {
    yield put(createCompetitionFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

function* CompetitionsSaga() {
  yield takeEvery(GET_COMPETITIONS_START, fetchCompetitions);
  yield takeLatest(CREATE_COMPETITION_START, createCompetition);
}

export default CompetitionsSaga;
