
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table, Card, CardBody, Button, Row, Col, Badge, Pagination, PaginationItem, PaginationLink 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import * as content from "content";
import { getCompetitionsStart } from "store/forex/ib/competition/actions";

const CompetitionsList = ({ t }) => {
  const dispatch = useDispatch();
  const { competitions, competitionsPagination, loading } = useSelector((state) => state?.forex?.ib?.competitionsReducer) || {};
  const imagesUrl = content.imagesUrl;

  const handlePageChange = (page) => {
    dispatch(getCompetitionsStart({
      page,
      limit: 1000000000 
    }));
  };

  // const formatDate = (dateStr) => {
  //   const date = new Date(dateStr);
  //   return date.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric"
  //   });
  // };

  // Create page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = [];
    const { page, totalPages } = competitionsPagination;
    
    let startPage = Math.max(1, page - 2);
    let endPage = Math.min(totalPages, page + 2);
    
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) pageNumbers.push("...");
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  if (!competitions || competitions.length === 0) {
    return (
      <div className="text-center p-4">
        <p>{t("No competitions found. Create a new one to get started.")}</p>
      </div>
    );
  }

  return (
    <div>
      <div className="table-responsive">
        <Table className="table-centered table-nowrap mb-0">
          <thead>
            <tr>
              <th>{t("ID")}</th>
              <th>{t("Competition Date")}</th>
              <th>{t("Address")}</th>
              <th>{t("Information")}</th>
              <th>{t("Prizes")}</th>
              <th>{t("Images")}</th>
            </tr>
          </thead>
          <tbody>
            {competitions.map((competition) => (
              <tr key={competition?._id}>
                <td>{competition?.recordId || "-"}</td>
                {/* <td>{formatDate(competition.competitionDate)}</td> */}
                <td>{competition?.competitionDate}</td>
                <td>{competition?.address}</td>
                <td>
                  <div style={{
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis" 
                  }}>
                    {competition?.info}
                  </div>
                </td>
                <td>
                  {competition?.prizes && competition?.prizes.length > 0 
                    ? competition?.prizes.map((prize, index) => (
                      <div key={index}>{prize}</div>
                    ))
                    : "-"}
                </td>
                <td>
                  {competition?.images && competition?.images.length > 0 ? (
                    <div className="avatar-group">
                      {competition?.images.slice(0, 2).map((image, index) => (
                        <a
                          key={index}
                          className="d-inline-block"
                          // href={`${imagesUrl}/${image}`}
                          href={`${image}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            // src={`${imagesUrl}/${image}`}
                            src={image}
                            alt={`Competition ${index + 1}`}
                            className="rounded avatar-sm"
                          />
                        </a>
                      ))}
                      {competition?.images?.length > 2 && (
                        <span className="avatar-group-text">
                          +{competition?.images?.length - 2}
                        </span>
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      {competitionsPagination?.totalPages > 1 && (
        <Row>
          <Col lg="12">
            <Pagination className="pagination pagination-rounded justify-content-center mt-4">
              <PaginationItem disabled={!competitionsPagination?.hasPrevPage}>
                <PaginationLink
                  previous
                  onClick={() => handlePageChange(competitionsPagination?.prevPage)}
                />
              </PaginationItem>
              
              {getPageNumbers().map((pageNum, index) => (
                pageNum === "..." ? (
                  <PaginationItem key={`ellipsis-${index}`} disabled>
                    <PaginationLink>...</PaginationLink>
                  </PaginationItem>
                ) : (
                  <PaginationItem key={pageNum} active={pageNum === competitionsPagination?.page}>
                    <PaginationLink onClick={() => handlePageChange(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                )
              ))}
              
              <PaginationItem disabled={!competitionsPagination?.hasNextPage}>
                <PaginationLink
                  next
                  onClick={() => handlePageChange(competitionsPagination?.nextPage)}
                />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default withTranslation()(CompetitionsList);