
const initalState = {
  deposits:[],
  forexDeposits:[],
  loading:false,
  error:"",
  modalClear:false,
  depositResponseMessage:"",
  depositChangeStatusSuccess: false,
  depostiChangeStatusFail: false
};

const competitionReducer = (state = { initalState }, action)=>{
  switch (action.type){
    case "FETCH_COMPETITIONS_START":
      state = {
        ...state,
        loading:true,
        error:""
      };
      break;
    case "FETCH_COMPETITIONS_SUCCESS":
      state = {
        competitions: [...action.payload.result.docs],
        totalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        loading: false,       
      };
      break;

  }
  return state;
};
export default competitionReducer;
