
import * as axiosHelper from "./api_helper";

export const getCompetitionsAPI = async () => {
  const result = await axiosHelper.get("/ib-competitions");
  if (result.status)
    return result.result;
  else throw new Error(result.message);
};

export const createCompetitionAPI = (data) => {
  const formData = new FormData();
  
  // Add text fields
  formData.append("address", data.address);
  formData.append("info", data.info);
  formData.append("competitionDate", data.competitionDate);
  
  // Handle prizes array
  if (data.prizes && Array.isArray(data.prizes)) {
    data.prizes.forEach(prize => {
      formData.append("prizes", prize);
    });
  } else if (data.prizes) {
    formData.append("prizes", data.prizes);
  }
  
  // Handle images
  if (data.images && data.images.length > 0) {
    data.images.forEach(image => {
      formData.append("images", image);
    });
  }
  
  return axiosHelper.post("/ib-competitions", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};